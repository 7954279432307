<template>
  <!-- Dynamically display images within a Bootstrap row -->
  <div v-for="(image, index) in images" :key="index" class="col-md-1 col-2">
    <div :class="{ 'codeicons': true, 'p-2': true, [category]: true}">
      <img :src="image.image_url" :alt="`Image ${index}`" class="img-fluid animate__animated" :id="image.name"
      @mouseover="handleMouseOver(index, image.name, image.category)"
        @mouseleave="handleMouseLeave(index)"
        @touchstart="handleTouchStart(index, image.name, image.category)"
        @touchend="handleTouchEnd(index)"
        ref="iconRefs">
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: 'CodeIcons',
  props: {
    category: String,
    flavor: String,
    hoverText: String,
  },
  data() {
    return {
      showApp: true,
      showB1: false,
      showB2: false,
      showB3: false,
      showB4: false,
      showB5: false,
      images: [],
      isHovering: false,
    };
  },
  methods: {
    toggle(property) {
      console.log('Toggling', property);
      this[property] = !this[property];
    },
    FancyName(category) {
      if (category === 'WebOpen') {
        return 'Web Development';
      } else if (category === 'ProgramsOpen') {
        return 'Programming';
      } else if (category === 'Videos') {
        return 'Video Editing';
      } else {
        return category;
      }
    },
    fetchImages() {
      let apiUrl;
      const userAgent = navigator.userAgent;
      if (process.env.NODE_ENV === 'production' || (/Mobi|Android/i.test(userAgent))) {
        apiUrl = 'https://sinlessproject.com/codeIcons/';
      } else {
        apiUrl = 'http://localhost:3120/codeIcons/';
      }
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // If category prop is provided, filter images by category
          if (this.category) {
            this.images = data.filter(item => item.category === this.category);
          }
          console.log(this.images);
        });
    },
    handleMouseOver(index, name, category) {
      this.$refs.iconRefs[index].classList.add('animate__rubberBand');
      this.$emit('imageHover', name, category);
    },
    handleMouseLeave(index) {
      this.$refs.iconRefs[index].classList.remove('animate__rubberBand');
      this.$emit('imageLeave');
    },
    handleTouchStart(index, name, category) {
      this.$refs.iconRefs[index].classList.add('animate__rubberBand');
      this.$emit('imageHover', name, category);
    },
    handleTouchEnd(index) {
      this.$refs.iconRefs[index].classList.remove('animate__rubberBand');
      this.$emit('imageLeave');
    },
  },
  mounted() {
    document.title = "XV's Portfolio";
    this.fetchImages();
  },
}
</script>