import { createRouter, createWebHistory } from 'vue-router';
import IndexMain from './../index/IndexMain.vue';
import PortfolioMain from './../portfolio/PortfolioMain.vue';

const router = createRouter({
  history: createWebHistory(), // Use HTML5 history mode
  routes: [
    {
      path: '/',
      name: 'index',
      component: IndexMain,
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: PortfolioMain,
    },
  ],
});

export default router;