<!-- src/components/index.vue -->

<template>
  <section id="portfolio">
    <div class="container mx-auto xtramargins " data-aos="fade-right">
      <div class="row row-col-sm-2 justify-content-center">
        <div id="firstobject" class="col p-4 align-self-center animate__animated" @mouseenter="addTada" @mouseleave="removeTada">
          <img src="./../../Images/ThroneSeat.png" class="columnimageleft align-self-center">
        </div>
        <div class="col p-4 align-self-center text-center aboutthis">
          <h1 class="flex-grow-1 titler textmain">My Portfolio</h1>
          <p class="">
            Born in Queens NY
            <br> I've been developing and designing since 2018, improving and expanding my skills over the years.
            In 2023, I founded Sinless Studios to have a collective place to release my projects.
            The most notable project being the Sins of Dissentia comic & website.
            <br>
            <br>Contact info
            <br><a class="textmain" href="mailto:ricardojparson@sinlessstudios.com">ricardojparson@sinlessstudios.com</a>
          </p>
        </div>
      </div>
    </div>

    <div class="section-divider"></div>
    <p class="text-center textmain mt-5 xtramargins" data-aos="fade-right">
      <br>Below lies a showcase of the projects I’ve worked on. 
      <br>This list is not cumulative, some things were excluded or lost with time. 
      <br>Archive means work that was created before 2022.
      <br>The oldest dates back to 2016. 
    </p>

    
    <PortfolioSection class="my-5"/>
    <div class="section-divider"></div>
    <div class="row align-self-center justify-content-center mt-5 xtramargins" data-aos="fade-right">
      <div class="col-9 p-4 align-self-center">
        <FlavortextforCodeIcons :text="hoverText" />
              <div class="row no-gutters justify-content-center align-items-center">
              <CodeIcons flavor="Operating Systems Specialties" category="Visual Design" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="Office" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="Serverside" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="Programming" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="Game Engines" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="Programs" @imageHover="handleHover" @imageLeave="handleLeave"/>
              <CodeIcons flavor="Operating Systems Specialties" category="OS" @imageHover="handleHover" @imageLeave="handleLeave"/>
          </div>
      </div>
    </div>
  </section>
</template>
<style>
/* Define the hide class */
.hide {
  visibility: hidden;
}
</style>
<script>

import 'bootstrap/dist/css/bootstrap.min.css';
import CodeIcons from './CodeIcons.vue';
import FlavortextforCodeIcons from './FlavortextforCodeIcons.vue'; 
import PortfolioSection from './PortfolioSection.vue';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'animate.css';
export default {
  name: 'TwoColumnSection',
  components: {
    CodeIcons,
    FlavortextforCodeIcons,
    PortfolioSection,
    },
    mounted() {
      AOS.init();
    },
    methods: {
    toggle(property) {
      this[property] = !this[property];
    },
    handleHover(name, category) {
        let modifiedCategory;

      switch (category) {
        case 'Visual Design':
          modifiedCategory = 'Design Software';
          this.hoverText = `${name} is a ${modifiedCategory} I'm familiar with`;
          break;
        case 'Office':
          modifiedCategory = 'Microsoft';
          this.hoverText = `I've dabbled with ${modifiedCategory} ${name}`;
          break;
        case 'Programming':
          modifiedCategory = 'Programming Language/Toolkit';
          this.hoverText = `${name} is a ${modifiedCategory} I'm familiar with`;
          break;
        case 'Serverside':
          this.hoverText = `${name} is used often in my serverside work`;
          break;
        case 'Programs':
          this.hoverText = `${name} is a program used often in my workflow`;
          break;
        case 'OS':
          this.hoverText = `${name} is an operating system I've versed in`;
          break;
        case 'Game Engines':
        this.hoverText = `${name} is an Game Engine I'm familiar with`;
        break;
        // Add more cases as needed
        default:
          modifiedCategory = category; // No modification for categories not explicitly handled
          this.hoverText = `${name} is a ${modifiedCategory} I'm familiar with`;
        }
    
      },
      handleLeave() {
        this.hoverText = 'Neat right? Hover over another icon to see my what it is.';
      },
      addTada() {
      const element = document.getElementById('firstobject');
      element.classList.add('animate__tada');
      },
      removeTada() {
        const element = document.getElementById('firstobject');
        element.classList.remove('animate__tada');
      },
  },
  data() {
      return {
        images: [],
        hoverText: 'Hover over an icon to see my what it is.',
      };
    },
}

</script>
