<template>

  <div v-if="componentType === 'imageComponent'" class="row align-items-center justify-content-center m-1" data-aos="zoom-in">
      
      <component :is="componentType"
        v-for="image in sortedelements"
        :key="image.image_url"
        :image="image"
      />

  </div>

  <div v-if="componentType !== 'imageComponent'" class="row mr-5 ml-1">
      <component :is="componentType"
        v-for="image in sortedelements"
        :key="image.image_url"
        :image="image"
      />
  </div>
</template>

<script>
import imageComponent from './imageComponent.vue';
import videoComponent from './videoComponent.vue';
import audioComponent from './audioComponent.vue';
import webComponent from './webComponent.vue';
import ProgramComponent from './ProgramComponent.vue';


export default {
  components: {
    imageComponent,
    videoComponent,
    audioComponent,
    webComponent,
    ProgramComponent,
  },
  props: ['year', 'componentType', 'category'],
  data() {
    let sanitizedCategory = this.category.replace(/\s+/g, '_');
    return {
      showStates: {},
      sanitizedCategory: sanitizedCategory,
      images: [],
    };
  },
  computed: {
    filteredImages() {
      let filtered = this.images.filter(image => image.category === this.category && parseInt(image.year) === parseInt(this.year));
      console.log('Filtered images:', filtered);
      return filtered;
    },
    sortedelements() {
      return [...this.filteredImages].sort((a, b) => a.ranking - b.ranking);
    },
    fetchStates() {
      let sanitizedCategory = this.category.replace(/\s+/g, '_');
      let key = 'fetch' + sanitizedCategory + this.year;
      return { [key]: this.filteredImages };
    },
    formattedYear() {
        return this.year === '2016' ? 'Archive' : this.year;
    },
  },
  created() {
    let apiUrl;
    const userAgent = navigator.userAgent;
    if (process.env.NODE_ENV === 'production' || (/Mobi|Android/i.test(userAgent))) {
      apiUrl = 'https://sinlessproject.com/images/';
    } else {
      apiUrl = 'http://localhost:3120/images/';
    }
    let sanitizedCategory = this.category.replace(/\s+/g, '_');
    this.showStates['show' + sanitizedCategory + this.year] = false;
          fetch(apiUrl) // Replace with the URL of your Express.js API
            .then(response => response.json())
            .then(data => {
              this.images = data;
              console.log('Images:', this.images);
              this.video = data;
              this.audio = data;
            });
    },
  methods: {
    toggle(key) {
      console.log('Toggling', key);
      this.showStates[key] = !this.showStates[key];
    }
  },
};
</script>