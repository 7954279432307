<!-- src/components/index.vue -->

<template>
    <div class="image-container col-lg-3 col-md-4 mb-2">
      <a :href="image.image_url" target="_blank">
        <img :src="image.image_url" :alt="image.description" class="fullimage align-self-center mb-4 mb-sm-0">
        <div class="image-overlay">
          <h6 class="textmain titler px-5">{{ image.name }}</h6>
          <p class="p-5">{{ image.description }}</p>
        </div>
      </a>
    </div>
</template>
<style scoped>
.image-container {
  position: relative;
  overflow: hidden;
}
.fullimage {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Removes bottom space/margin of inline elements */
}
.image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* Add more styling here for background, color, etc. */
}
</style>
<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'imageComponent',
  props: {
    image: Object
  }
}
</script>
