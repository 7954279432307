<!-- src/App.vue -->

<template>
  <div id="app">
        <router-view></router-view>
  </div>
  <main>
    <TwoColumnSection/>

  </main>
</template>
<style src="./../../../styles/styles.css"></style>

<script>
  //import imageBlock from './../../components/imageBlock.vue';
  import TwoColumnSection from './../../components/TwoColumnSection.vue'; 
  import 'animate.css';
  export default {
    name: 'PortfolioMain',
    components: {
    //imageBlock,
    TwoColumnSection,
    },
    // Hide the data until user selects it
    data() {
      return {
        showApp: true,
        showB1:  false,
        showB2:  false,
        showB3:  false,
        showB4:  false,
        showB5:  false,
      };
    },
    methods: {
      toggle(property) {
        console.log('Toggling', property);
        this[property] = !this[property];
      }
    },
    mounted(){
      document.title = "XV's Portfolio";
    }
  }

</script>