<!-- src/components/index.vue -->

<template>
  <div class="row no-gutters mb-4 m-4 p-3 chapter-card">
    <div class="col-md-6 col-12 mb-3 align-self-center">
      <div class="row no-gutters ">
        <h5 class="textmain titler">{{ image.name }}</h5>
        <p class="">{{ image.description }}</p>
      </div>
    </div>
    <div class="col-md-6 col-12 justify-content-center align-items-center align-self-center">
        <div v-for="(download, index) in image.download" :key="index">
            <div class="d-flex justify-content-center align-items-center">
              <h5 class="m-1">{{ download }} </h5>
              <a :href="`${image.image_url}${download}`" target="_blank"><i class="bi bi-download textmain m-1"></i></a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'ProgramComponent',
  props: {
    image: Object
  },
};


</script>
