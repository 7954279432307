<!-- src/App.vue -->

<template>
  <main>
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
    <div class="row justify-content-center align-items-center animate__animated animate__fadeInLeft">
      <a class="col-8 animate__animated" :class="{ 'animate__fadeOutRight': isClassAdded }" @click="addClass" :href="apiUrl">
        <div class="d-flex align-items-center textmain"><h1>Hello</h1><h4>, I'm Ricardo JP</h4></div>
        <h6 class="textmain">Check out my work<i class="bi bi-arrow-right-circle-fill ml-5"></i></h6>
      </a>
    </div> 
  </main>
</template>
<style src="./../../../styles/styles.css"></style>

<style scoped>
/* For small screens (less than 576px) */
@media (max-width: 575.98px) {
  h1 {
    font-size: 48px;
    font-family: 'Poppins-Bold';
  }
  h4 {
    font-size: 24px;
    font-family: 'Poppins-Bold';
  }
  h6 {
    font-size: 18px;
    font-family: 'Poppins-Bold';
  }
  .bi-arrow-right-circle-fill{
  font-size: 24px;
}
}

/* For medium screens (between 576px and 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  h1 {
    font-size: 64px;
    font-family: 'Poppins-Bold';
  }
  h4 {
    font-size: 28px;
    font-family: 'Poppins-Bold';
  }
  h6 {
    font-size: 24px;
    font-family: 'Poppins-Bold';
  }
  .bi-arrow-right-circle-fill{
  font-size: 36px;
}
}

/* For large screens (768px and above) */
@media (min-width: 768px) {
  h1 {
    font-size: 112px;
    font-family: 'Poppins-Bold';
  }
  h4 {
    font-size: 64px;
    font-family: 'Poppins-Bold';
  }
  h6 {
    font-size: 48px;
    font-family: 'Poppins-Bold';
  }
  .bi-arrow-right-circle-fill{
  font-size: 48px;
}
}



a {
  text-decoration: none;
}

.row {
  height: 100vh;
  width: 100vw;
}
main {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #151515 0%, #000000 100%);
  overflow: hidden;
}

#stars, #stars:after, #stars2, #stars2:after, #stars3, #stars3:after {
  background: transparent;
}

#stars, #stars2, #stars3 {
  position: absolute;
  top: 0;
  width: 1px;
  height: 1px;
}

#stars {
  animation: animStar 50s linear infinite;
}

#stars2 {
  width: 2px;
  height: 2px;
  animation: animStar 100s linear infinite;
}

#stars3 {
  width: 3px;
  height: 3px;
  animation: animStar 150s linear infinite;
}
#stars, #stars:after {
  box-shadow: 1000px 1000px #FFF, 1200px 900px #FFF, 1500px 1400px #FFF, 500px 1200px #FFF, 800px 1500px #FFF;
}

#stars2, #stars2:after {
  box-shadow: 1100px 1100px #FFF, 1300px 950px #FFF, 1600px 1450px #FFF, 600px 1300px #FFF, 900px 1600px #FFF;
}

#stars3, #stars3:after {
  box-shadow: 1200px 1200px #FFF, 1400px 1000px #FFF, 1700px 1500px #FFF, 700px 1400px #FFF, 1000px 1700px #FFF;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
</style>
<script>
  //import imageBlock from './../../components/imageBlock.vue';
  import 'animate.css';
  
  export default {
    name: 'IndexMain',
    components: {
    //imageBlock,
   
    },
    // Hide the data until user selects it
    data() {
      return {
        showApp: true,
        showB1:  false,
        showB2:  false,
        showB3:  false,
        showB4:  false,
        showB5:  false,
        isClassAdded: false,
        apiUrl: '',
      };
    },
    methods: {
      toggle(property) {
        console.log('Toggling', property);
        this[property] = !this[property];
      },
      addClass(event) {
      // Prevent the default navigation
      event.preventDefault();

      // Add the class to trigger the animation
      this.isClassAdded = true;

      // Wait for the animation to complete before navigating
      // Assuming the animation duration is 1 second (1000 milliseconds)
      setTimeout(() => {
        window.location.href = this.apiUrl;
      }, 1000); // Adjust the timeout duration based on your actual animation duration
    },
    },
    mounted(){
      document.title = "XV's Portfolio";
    },
    created() {
      const userAgent = navigator.userAgent;
      if (process.env.NODE_ENV === 'production' || (/Mobi|Android/i.test(userAgent))) {
        this.apiUrl = 'https://sinlessproject.com/portfolio';
      } else {
        this.apiUrl = 'http://localhost:8080/portfolio';
      }

  },
  }

</script>