<template>
  <h5 class="FlavortextforCodeIcons text-center textmain">{{ text }}</h5>
</template>

<script>
export default {
  name: 'FlavortextforCodeIcons',
  props: {
    text: {
      type: String,
    }
  },
}
</script>