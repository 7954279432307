<!-- src/components/index.vue -->

<template>
  <div class="row no-gutters mb-4 m-4 p-3 chapter-card">
    <div class="col-md-6 col-12 d-flex justify-content-center align-items-center">
      <a :href="image.view" target="_blank">
        <img :src="image.image_url" :alt="image.description" class="fullimage align-self-center mb-4 mb-sm-0">
      </a>
    </div>
    <div class="col-md-6 col-12 align-items-center">
      
      <!-- <p>Category: {{ image.category }}</p> -->
      <!-- <p>Year: {{ image.year }}</p> -->
      <div class="row no-gutters">
        <h5 class="textmain titler">{{ image.name }}</h5>
        <p class="text-align-center">{{ image.description }}</p>
      </div>
      <div class="">
        <div class="d-flex justify-content-center align-items-center">
          <h5 class="m-1">View</h5><a :href="image.view" class="textmain m-1" target="_blank"><i class="bi bi-box-arrow-up-right textmain m-1"></i></a>
        </div>
        <div v-for="(download, index) in image.download" :key="index">
          <div class="d-flex justify-content-center align-items-center" v-if="download">
            <h5 class="m-1">Download</h5><a :href="`${download}`" target="_blank"><i class="bi bi-download textmain m-1"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'webComponent',
  props: {
    image: Object
  }
}

</script>
