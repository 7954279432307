<!-- src/components/index.vue -->

<template>
  <section data-aos="fade-right">
    <!-- 

    <h1 class="flex-grow-1 titler textmain">XV'S Portfolio</h1> -->
    
    <ul class="d-flex justify-content-center flex-wrap align-items-center">
      <li v-for="category in categories" :key="category" class="portfolio"><h3 :class="{ 'underdash': activeCategory === category }" @click="toggleCategory(category)">{{ FancyName(category) }}</h3></li>
    </ul>
    
    
    <div v-for="category in Object.keys(yearsByCategory)" :key="category" v-show="activeCategory === category">
      <ul class="d-flex justify-content-center flex-wrap align-items-center ">
        <li v-for="year in yearsByCategory[category]" :key="year" class="portfolio"><h3 :class="{ 'underdash': activeYear === year }" @click="toggleYear(year)">{{ year }}</h3></li>
      </ul>
    </div>
    <imageBlock v-if="activeYear && activeCategory" :year="activeYear" :category="activeCategory" :componentType="componentName(activeCategory)"/>
    
  </section>
</template>


<script>
  import 'bootstrap/dist/css/bootstrap.min.css';
  import imageBlock from './../components/imageBlock.vue';
  import AOS from 'aos';
  import 'aos/dist/aos.css'; // Import AOS styles
  export default {
    name: 'App',
    components: {
    imageBlock,
    },
    // Hide the data until user selects it
    data() {
      return {
        showApp: true,
        categories: [],
        years: [],
        activeCategory: null,
        activeYear: null,
        yearsByCategory: [],
        images: [],
        hoverText: 'Hover over an icon to see my what it is.',
      };
    },
    methods: {
      toggleCategory(property) {
        //console.log('Toggling', property);
        this.activeCategory = property;
        this.activeYear = null;
      },
      toggleYear(property) {
        //console.log('Toggling', property);
        this.activeYear = property;
      },
      FancyName(category) {
        //console.log(category + "here1");
        if (category === 'WebOpen') {
          return 'Web Development';
        } else if (category === 'ProgramsOpen') {
          return 'Programming';
          
        } else if (category === 'Videos') {
          return 'Video Editing';
        } else {
          return category;
        }
      },
      componentName(category) {
        console.log(category + "here1");
        if (category === 'WebOpen') {
          return 'webComponent';
        } else if (category === 'ProgramsOpen') {
          return 'ProgramComponent';
          
        } else if (category === 'Digital Art' || category === 'Photoshop') {
          return 'imageComponent';
          
        } else if (category === 'Videos') {
          return 'videoComponent';
        } else if (category === 'Music') {
          return 'audioComponent';
        } else {
          return 'miscComponent';
        }
      },
    },
    mounted(){
      document.title = "XV's Portfolio";
      AOS.init();
    },
    created() {
    let apiUrl;
    const userAgent = navigator.userAgent;
    if (process.env.NODE_ENV === 'production' || (/Mobi|Android/i.test(userAgent))) {
      apiUrl = 'https://sinlessproject.com/images/';
    } else {
      apiUrl = 'http://localhost:3120/images/';
    }
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        const categories = data.map(item => item.category);
        this.categories = [...new Set(categories)];

        this.yearsByCategory = this.categories.reduce((acc, category) => {
          const years = data
            .filter(item => item.category === category)
            .map(item => item.year);
          acc[category] = [...new Set(years)];
          return acc;
        }, {});
      });
    },
  }
</script>
