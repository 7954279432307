<!-- src/components/index.vue -->

<template>
  <div class="row no-gutters align-items-center mb-4 m-4 p-3 chapter-card">
    <div class="col-md-6 col-12 mb-3 d-flex justify-content-center audiobox">
        <audio ref="audioRef" controls class="align-self-center audio-control">
          <source :src="image.image_url" type="audio/mp3" class="m-4 m-sm-0">
        </audio>
    </div>
    <div class="col-md-6 col-12">
      <h5 class="textmain titler">{{ image.name }}</h5>
      <p class="">{{ image.description }}</p>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'audioComponent',
  props: {
    image: Object
  }
}

</script>
