<!-- src/components/index.vue -->

<template>
  <div class="row no-gutters align-items-center mb-4 m-4 p-3 chapter-card">
    <div class="col-md-6 col-12 mb-3 d-flex justify-content-center">
        <video controls class="align-self-center" style="max-width: 100%; max-height: 100%;">
          <source :src="image.image_url" type="video/mp4" class="m-4 m-sm-0">
          Your browser does not support the video tag.
        </video>
    </div>
    <div class="col-md-6 col-12">
      <h5 class="textmain titler">{{ image.name }}</h5>
      <p class="">{{ image.description }}</p>
    </div>
  </div>
  
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'videoComponent',
  props: {
    image: Object
  }
}

</script>

